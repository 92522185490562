/* Remove default Swiper card shadows */
.swiper-slide-shadow {
    display: none !important; /* Hides any shadows added by the effect */
}

.swiper-slide {
    background: transparent !important;
    box-shadow: none !important;
}

.swiper-effect-cards .swiper-slide {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
}
