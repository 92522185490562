.tr-single-slider .carousel .thumbs-wrapper .thumb {
    border: none !important;
    outline: none !important;
    width: max-content !important;
    overflow: 'hidden';
}

.map-modal {
    position: relative  important;
}

.map-modal-map {
   z-index: 99999999999;
}

.tr-single-slider .carousel .thumbs-wrapper .thumb.selected {
    border: 1px solid rgba(255, 255, 255, 0.5) !important;
    outline: none !important;
}

.leaflet-control-attribution {
    display: none !important;
}

.gmnoprint.gm-style-mtc-bbw {
 display: none;
}

/* In your CSS file (e.g., App.css) */
.gm-fullscreen-control {
    background: url('/public/icons/expand.svg') no-repeat center center !important;
    background-size: contain;
    width: 40px; /* Adjust the size as needed */
    height: 40px; /* Adjust the size as needed */
    z-index: 100000;
    border-radius: 50% !important;
    box-shadow: none !important;
    background: rgba(32, 31, 31, 0.33) !important;
    /* position: absolute;
    right: 0; */
}

.gm-fullscreen-control::before {
    background: url('/public/icons/expand.svg') no-repeat center center !important;
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
    transform: translate(-50%, -50%);
    z-index: 10000;
 }

.gm-fullscreen-control img {
    background: url('/public/icons/expand.svg') no-repeat center center !important;
    display: none !important;
    src: 'none !important'
}

.gm-style-cc {
 display: none;
}

.slider-highlight ul {
    transform: translate3d(0px, 0px, 0px);
    gap: 8px;
}

/* Default styling for extra-large screens (1440px and above) */
.slider-highlight {
    align-items: baseline;
    width: 740px;
    overflow: visible;
}

.slider-highlight .react-multi-carousel-item {
    width: 188px;
    /* padding-left: -300px; */
    transform-style: unset !important;
}

.homepage-carousel .carousel .control-dots {
    bottom: 50px;
}

/* Media query for large screens (between 1200px and 1440px) */
@media (max-width: 1440px) {
    .slider-highlight {
        width: 547px;
        /* padding-left: 84px; */
    }
    .slider-highlight .react-multi-carousel-item {
        width: 192px;
        /* padding-left: 30px; */
    }

    .slider-highlight ul {
        transform: translate3d(0px, 0px, 0px);
        gap: 10px;
    }
}

/* Media query for medium screens (between 768px and 1199px) */
@media (max-width: 1200px) {
    .slider-highlight {
        width: 630px;
    }
    .slider-highlight .react-multi-carousel-item {
        width: 170px;
        padding-left: 20px;
    }

    .slider-highlight ul {
        transform: translate3d(-20px, 0px, 0px);
        /* gap: 54px; */
    }
}

/* Media query for small screens (below 768px) */
@media (max-width: 768px) {
    .slider-highlight {
        width: 100%;
    }
    .slider-highlight .react-multi-carousel-item {
        width: 150px;
        padding-left: 10px;
    }

    .tr-single-slider .carousel {
        height: 100dvh;
    }
}



.slider-highlight .react-multiple-carousel__arrow {
    top: 150px;

}

.carousel .slide iframe {
    width: 300px !important;
    margin: 0 !important;
    border-radius: 20px !important;
}

.influencer-card-carousel .react-multi-carousel-track {
    gap: 75px;
}

.create-itinerary-drawer {
    z-index: 3000000;
}