@media (min-width: 960px) {
    .carousel-root {
        width: 100%
    }
    .carousel .control-dots {
        position: fixed;
        bottom: 20px;
        z-index: 10;
    }
    .carousel .control-dots .dot {
      height: 17px;
      width: 17px;
      opacity: .5;
    }
    .carousel .control-dots {
        width: 100%;
    }

    .carousel .slider {
      width: 100% !important;
      left: 10px;
    }
}
@media (max-width: 768px) {
    .carousel.carousel-slider {

        margin-top: 40px;
    }
    .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 95vw;
    transition: height .15s ease-in;
    display: flex;
    gap: 20%;
    justify-content: space-between;
    }

    .carousel .slider-wrapper.axis-horizontal .slider {
        -ms-box-orient: horizontal;
        display: -moz-flex;
        display: flex;
        gap: 164px;
    }
    .carousel .slide {
        min-width: 35% !important;
        left: 0;

      }

    .carousel .control-dots {
        position: fixed;
        bottom: 40px;
        z-index: 9999910;
    }

    .carousel .slider {
        margin: 0;
        padding: 0;
        position: relative;
        list-style: none;
        width: 35% !important;
        gap: 10px;
        /* left: 10px; */
    }
    /* .carousel .control-dots { position: absolute !important;} */
}
.carousel .control-dots {
    position: fixed;
    bottom: 40px;
    z-index: 9999910;
}

.carousel .control-dots .dot {
    height: 12px;
    width: 12px;
    opacity: .5;
  }

